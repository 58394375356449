import React, { useState } from "react"
import Slider from "react-slick"
import './style.sass'

const settingsGallery = {

  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  // focusOnSelect: true,


    responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
};

const Gallery = ({slider, sliderCoworking}) => {
  const [tabActive, updateTabStatus] = useState('smart');

  return (
    <div className="gallery">
      <div className="full-wrapper">
        <h2 className="gallery__title">Будущее создается здесь</h2>
        <div className="gallery__tabs">
          <span className={`gallery__tab ${tabActive === 'smart' ? 'active' : ''}`} onClick={e=>updateTabStatus('smart')}>Смарт-офис</span>
          <span className={`gallery__tab ${tabActive === 'coworking' ? 'active' : ''}`} onClick={e=>updateTabStatus('coworking')}>Coworking</span>
        </div>

      <div className="tariff__gallery">
          {
            tabActive === 'smart' && (
              <Slider {...settingsGallery} className="tariff-gallery">
                {
                  slider.map((item, index) => (
                    <div key={index}
                         className="tariff-gallery__item"
                    >
                      <img src={item.photo.publicURL} alt="tariff"/>
                    </div>
                  ))
                }
              </Slider>
            )
          }
          {
            tabActive === 'coworking' && (
              <Slider {...settingsGallery} className="tariff-gallery">
                {
                  sliderCoworking.map((item, index) => (
                    <div key={index}
                         className="tariff-gallery__item"
                    >
                      <img src={item.photo.publicURL} alt="tariff"/>
                    </div>
                  ))
                }
              </Slider>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Gallery
