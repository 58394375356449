import React, { Component } from "react"
import PropTypes from "prop-types"
import "./style.sass"
import Video from "../video/video"

class MainInfrastructure extends Component {
  state = {
    data: this.props.data,
  }

  render() {
    const { data } = this.state

    return (
      <div className="wrapper">
        <div className="full-wrapper">
          <div className="wrapper__content">
            <div className="main-infrastructure">
              <div className="main-infrastructure__title">
                <h2>Технопарк Сколково — это удобно</h2>
              </div>
              <div className="main-infrastructure__subtitle">
                Инфраструктура Технопарка
              </div>
              <div className="main-infrastructure__content">
                <div className="main-infrastructure__info">
                  <div className="main-infrastructure__img">
                    <img
                      src="/images/infrastructure-1.jpg"
                      alt="infrastructure"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MainInfrastructure.propTypes = {}

export default MainInfrastructure
