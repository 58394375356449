import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.sass'
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
// import TariffMore from "../tariffMore";

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
};

const data = [
    {
        title: "Доступ к сообществу",
        img: "/images/svg/tariff/icon-1.svg",
        options: [
            "Питч-сессии с инвесторами и венчурными фондами",
            "Доступ в MegaHackspace и информационную систему «Стартап Хаб»",
            "Аренда жилья в Сколково",
            "Специальные условия в Международной гимназии «Сколково»",
            "Эксклюзивные предложения от партнеров",
            "Возможность проживания на территории ИЦ «Сколково»",
            "Специальные программы от гимназии для детей",
        ]
    },
    {
        title: "Мероприятия",
        img: "/images/svg/tariff/icon-2.svg",
        options: [
            "Ежедневные мероприятия: лекции, митапы, социокультурные активности",
            "Тренажерный зал и групповые занятия спортом",
            "Бизнес-завтраки",
            "Образовательные программы ",
            "Встречи с региональными и международными делегациями",
            "Нетворкинг",
        ]
    },
    {
        title: "Офисы и сервисы",
        img: "/images/svg/tariff/icon-3.svg",
        options: [
            "Юридический адрес",
            "Полностью укомплектованный офис под ключ и переговорные",
            "Все, что необходимо для комфортной работы: от кофе и снеков до станций печати и канцтоваров",
            "IT-поддержка",
            "Доступ к HR-сервису",
            "Консьерж-сервис",
            "Полное сопровождение",
            "Регулярная уборка помещений",
            "Электричество и Wi-Fi",
        ]
    },
];

class TariffIncludes extends Component {
    state = {
        includes: this.props.includes,
        more: this.props.more,
        render: false
    }

    componentDidMount() {
        this.setState({
            render: true
        })
    }

    render() {
        const { includes, more, render } = this.state;

        return (
            <div className="wrapper" id='tarif'>
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="tariff-includes">
                            <div className="tariff-includes__title">
                                <h2 dangerouslySetInnerHTML={{__html: includes.title}}/>
                            </div>
                            <div className="tariff-includes__list">
                                {
                                    render ?
                                        <Slider {...settings} className="tariff-includes-list">
                                            {
                                                data.map((item, index) => (
                                                    <div key={index}
                                                         className="tariff-includes-list__item"
                                                    >
                                                        <div className="tariff-includes-item">
                                                            <div className="tariff-includes-item__head">
                                                                <div className="tariff-includes-item__title">{item.title}</div>
                                                                <div className="tariff-includes-item__img">
                                                                    <img src={item.img} alt={item.title}/>
                                                                </div>
                                                            </div>
                                                            <div className="tariff-includes-item__content">
                                                                <ul>
                                                                    {
                                                                        item.options.map((line, index) => (
                                                                            <li key={index}
                                                                                dangerouslySetInnerHTML={{__html: line}}
                                                                            />
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Slider> :
                                        ''
                                }
                            </div>
                        </div>
                        {/*<TariffMore />*/}
                    </div>
                </div>
            </div>
        );
    }
}

TariffIncludes.propTypes = {};

export default TariffIncludes;
