import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./style.sass";

class Video extends Component {
    state = {
        previewSrcUrl: this.props.previewSrcUrl ? this.props.previewSrcUrl : null,
        videoSrcURL:   this.props.videoSrcURL,
        play:          false
    };

    handleClick() {
        this.setState({
            play: true
        });

        const $youtubeFrame = document.querySelectorAll('.video iframe')[0];

        const loc      = $youtubeFrame.getAttribute('src');
        const startloc = loc + "&autoplay=1";
        $youtubeFrame.setAttribute('src', startloc);
    }

    render() {
        const {previewSrcUrl, videoSrcURL} = this.props;
        const {play}                       = this.state;

        return (
            <div className="video">
                {
                    previewSrcUrl ?
                        <div className={"video__preview " + (play ? "video__preview--hidden" : "")} style={{backgroundImage: `url(${previewSrcUrl})`}}>
                            <div className="video__controls" onClick={this.handleClick.bind(this)}>
                                {/*<span>Смотреть видео</span>*/}
                            </div>
                        </div>
                        :
                        ''
                }

                <iframe
                    width="100%"
                    height="647"
                    src={videoSrcURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        );
    }
}

Video.propTypes = {
    previewSrcUrl: PropTypes.string,
    videoSrcURL:   PropTypes.string.isRequired
}

export default Video;