import React, {Component} from 'react';
import {Map, Placemark, YMaps, ZoomControl} from 'react-yandex-maps';
import PropTypes from 'prop-types';
import './style.sass';

const data = [
    {
        title: "Общественный транспорт",
        list: [
            "На электричке по диаметру D1 до станции «Сколково».",
            "На автобусах со станций «Славянский бульвар», «Молодежная», «Кутузовская», «Парк Победы» и «Тропарево».",
        ]
    },
    {
        title: "Автомобиль",
        list: [
            "Большой бульвар, 42 с1.",
            "На каршерингах Belkacar и Делимобиль.",
            "На такси Uber и Яндекс.Такси — для них организован бесшовный проезд.",
            "На личном автомобиле с Минского, Можайского и Сколковского шоссе.",
            "Припарковать автомобиль можно на любой перехватывающей парковке: «Технопарк», «Сколковская», БЦ «Амальтея» или ТЦ «Орбион». Для заезда на парковку пропуск не потребуется.",
        ]
    },
]

class MainTransport extends Component {

    render() {
        return (
            <div className="wrapper wrapper--other" id="transport">
                <div className="main-transport">
                    <div className="main-transport__content">
                        <div className="main-transport__info">
                            <div className="main-transport__info-title">
                                <h2>Как добраться</h2>
                            </div>
                            <div className="main-transport__list">
                                {
                                    data.map((item, index) => (
                                        <div key={index}
                                            className="main-transport__list-item">
                                            <div className="main-transport__list-item-title">{item.title}</div>
                                            <div className="main-transport__list-item-value">
                                                {item.list.map((line, i) => <div key={i}>{line}</div> )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="main-transport__button">
                                <a href="https://sk.ru/transport/" target="_blank">Больше информации</a>
                            </div>
                        </div>
                        <div className="main-transport__map">
                            <YMaps>
                                <Map
                                    defaultState={{
                                        center: [55.69288842143611,37.34746974865719],
                                        zoom: 16,
                                        controls: [],
                                        behaviors: ["ScrollZoom", "drag", "dblClickZoom", "rightMouseButtonMagnifier", "dblClickZoom", "multiTouch"]
                                    }}
                                    className="main-transport__map-body"
                                >
                                    <Placemark geometry={ [55.69288842143611,37.34746974865719] } />
                                    <ZoomControl />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MainTransport.propTypes = {};

export default MainTransport;
