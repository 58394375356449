import React, {Component} from "react"
import './style.sass'
import "../../../node_modules/slick-carousel/slick/slick.css";
import ResidentCount from "../residentCount"


const settingsAbout = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                arrows: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                arrows: true,
            }
        },
    ]
};

class Tariff extends Component {
    state = {
        tarif: this.props.tarif,
    };

    render() {
        const { tarif } = this.state;

        return (
            <div className="wrapper" id="about">
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="tariff">
                            <div className="tariff__header">
                                <h2>
                                    <span>{tarif.title1}&nbsp;</span>
                                </h2>
                            </div>
                            <div className="tariff__content">
                                <div className="tariff__content-desc">
                                    <div className="tariff__content-text"
                                         dangerouslySetInnerHTML={{__html: tarif.description}}
                                    />
                                </div>
                                <div className="tariff__content-img">
                                    <img
                                        src="/images/tariffImages/description-img.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                            <ResidentCount />
                    </div>
                </div>
            </div>
        );
    }
}

Tariff.propTypes = {};

export default Tariff;
