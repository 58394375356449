import React, {Component} from 'react';
import './style.sass'
import Video from '../video/video';
import Gallery from "../gallery";

class MainWhy extends Component {
    state = {
        data: this.props.data,
        slider: this.props.slider,
        sliderCoworking: this.props.sliderCoworking
    }

    render() {
        const { data, slider, sliderCoworking } = this.state;

        return (
            <div className="wrapper wrapper--bg wrapper--why">
                <Gallery slider={slider} sliderCoworking={sliderCoworking}/>
                <div className="full-wrapper">
                    <div className="wrapper__content">
                        <div className="main-why">
                            <div className="main-why__title">
                                <h2>
                                    Почему Технопарк &laquo;Сколково&raquo;
                                </h2>
                            </div>
                            <div className="main-why__content">
                                <div className="main-why__video">
                                    <Video
                                        videoSrcURL={data.video}
                                        previewSrcUrl={data.preview.publicURL}
                                    />
                                </div>

                                <div className="main-why__options why-options">
                                    <div className="why-options__item">
                                        <div className="why-options__num">
                                            96 000 м2
                                        </div>
                                        <div className="why-options__title">
                                            Лабораторий и офисов
                                        </div>
                                    </div>
                                    <div className="why-options__item">
                                        <div className="why-options__num">
                                            450+
                                        </div>
                                        <div className="why-options__title">
                                            Резидентов
                                        </div>
                                    </div>
                                    <div className="why-options__item">
                                        <div className="why-options__num">
                                            30+
                                        </div>
                                        <div className="why-options__title">
                                            Скаутинговых программ в год
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MainWhy.propTypes = {};

export default MainWhy;
