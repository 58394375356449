import React from "react"
import "./style.sass"

const ResidentCount = props => {
  return (
      <div className="resident-count">
        <h2 className="resident-count__main-title">Наши резиденты</h2>
        <div className="resident-count__list">
          <div className="resident-count__item">
            <div className="resident-count__wrap-icon">
              <img
                src="/images/svg/residentCount/resident-img-1.svg"
                alt=""
              />
            </div>
            <div className="resident-count__text">
              <span className="resident-count__title">
                Привлечение инвестиций
              </span>
              <span className="resident-count__text">
                Каждый четвертый стартап «Сколково» привлекал частные инвестиции
                в 2019 года, что значительно больше, чем годом ранее (в 2018 –
                каждый седьмой)
              </span>
            </div>
          </div>

          <div className="resident-count__item">
            <div className="resident-count__wrap-icon">
              <img src="/images/svg/residentCount/resident-img-2.svg" alt="" />
            </div>
            <div className="resident-count__text">
              <span className="resident-count__title">
                Рост валовой выручки
              </span>
              <span className="resident-count__text">
                За 2019-й год резиденты заработали немногим меньше, чем в сумме
                за 2017-й и 2018-й
              </span>
            </div>
          </div>

          <div className="resident-count__item">
            <div className="resident-count__wrap-icon">
              <img src="/images/svg/residentCount/resident-img-3.svg" alt="" />
            </div>
            <div className="resident-count__text">
              <span className="resident-count__title">
                Зарегистрированные патенты
              </span>
              <span className="resident-count__text">
                Деньги – не единственный параметр успеха «Сколково».
                Стимулирование разработки новых технологий – одна из главных
                задач Фонда, и она успешно решается. В 2019 году резиденты
                зарегистрировали в три раза больше патентов, чем за 2018-й.
              </span>
            </div>
          </div>

          <div className="resident-count__item">
            <div className="resident-count__wrap-icon">
              <img src="/images/svg/residentCount/resident-img-4.svg" alt="" />
            </div>
            <div className="resident-count__text">
              <span className="resident-count__title">
                Рост выручки
              </span>
              <span className="resident-count__text">
                За 3 года, прошедшие с запуска Технопарка, медианная выручка
                резидента выросла в сорок раз. Это стало возможным из-за эффекта
                экосистемы: в «Сколково» стартап любой стадии имеет доступ к
                необходимым средствам, чтобы сделать свой следующий шаг.
              </span>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ResidentCount
